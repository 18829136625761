/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

class Notifier {

    public static error(message: string): any {
        var error = $(document.createElement("div"));
        error.addClass("alert alert-danger fade in");        
        error.append(message);
        error.append(this.getButton());
        
        return error;
    }

    public static information(message: string): any {
        var information = $(document.createElement("div"));
        information.addClass("alert alert-info fade in");
        information.append(message);
        information.append(this.getButton());

        return information;
    }

    private static getButton(): any {
        var button = $(document.createElement("button"));
        button.attr("type", "button");
        button.attr("aria-hidden", "true");
        button.attr("data-dismiss", "alert");
        button.addClass("close");
        button.append("×"); // Note that this is not a lowercase "x" character

        return button;
    }
}